<template>


<div class="copyright" v-html="bq"></div>


</template>


<style >
.copyright{margin-top: 55px;}
</style>

<script>
import global from './common.vue'
export default {
  
      name: 'Bq',
      data() {
          return {
             bq:'', 
          }
      },
      created() {
    let that=this;
   let host=window.location.host; 
      this.$axios.get(global.http+'/api/bq',{params:{
         title:host
        }}
        ).then(
            (response) => {

that.bq=response.data.cont
    }


    )  

      }
      ,
      props: {

  },methods: {


  },
}
</script>