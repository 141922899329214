<template>

  <div class="g-flex-rsac th-tabbar" >

   <a aria-current="page"  @click="tz('/')"
  v-bind:class="(cur=='index')?'router-link-active router-link-exact-active':''">
    <div class="th-icon-text"  >
     <i class="iconfont icon-xingzhuangjiehe"  style="font-size: 24px;"></i>
     <div class="text"  style="font-size: 14px;">
      首页
     </div>
    </div></a>

   <a  @click="tz('/list')"
     v-bind:class="(cur=='list')?'router-link-active router-link-exact-active':''"
   >
    <div class="th-icon-text"  >
     <i class="iconfont icon-shuqian"  style="font-size: 24px;"></i>
     <div class="text"  style="font-size: 14px;">
      分类 
     </div>
    </div></a>



   <a  @click="tz('/user/vip?active=1')"
   
     v-bind:class="(cur=='fx')?'router-link-active router-link-exact-active huo':''"
    >
    <div class="th-icon-text1"  >
 <img src="/img/vip.png" alt="">
    <div class="text"  style="font-size: 14px;">
    会员免费
     </div>
    </div></a>



   <a  @click="tz(1)"
   
     v-bind:class="(cur=='user')?'router-link-active router-link-exact-active':''"
    >
    <div class="th-icon-text"  >
     <i class="iconfont icon-wode"  style="font-size: 24px;"></i>
     <div class="text"  style="font-size: 14px;">
      我的
     </div>
    </div></a>
  </div>

</template>


<style lang="less"  scoped>
.th-icon-text1{text-align: center;    color: #999;
img{width:38px;position: relative;left:0.02rem;}
}
.huo .th-icon-text1{color:#f00;}
</style>

<style >
    .th-tabbar {
    z-index: 9999;
    position: fixed;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    max-width: 6.8rem;
    height: .6rem;
    background-color: #fff;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    box-shadow: 0 -0.01rem 0.05rem #dcdcdc;
}
.g-flex-csac, .g-flex-rsac {
    -webkit-justify-content: space-around;
    justify-content: space-around;
}
.th-tabbar .th-icon-text {
    margin: 0 .2rem;
}
.th-icon-text {
    color: #999;
    text-align: center;
}
.th-icon-text .text{
    margin-top: .03rem;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  font-size: .24rem;
  overflow: hidden;
}


</style>

<script>

export default {
      name: 'Foot',
      data() {
          return {
              
          }
      },methods: {
        tz:function(val){
        /*
if('/'+this.cur==val){


}else{
  this.$router.push(val)
}
*/


if(val!=1){
this.$router.push(val)
}else{
  this.$router.push('/'+lujing);
}
        }
      },
      props: {
    cur: String
  }
}
</script>