<template>
<div class="home">
<div class="swiper">
 <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#1989fa" 
 >

  <van-swipe-item v-for="vo in data.banner" :key="vo.name" @click='tz2(vo.url)'>
  <a  >    <img :src=" url+vo.pic" alt="" ></a>
      </van-swipe-item>

  
</van-swipe>
</div>





  <div class="g-plr15" >
   <div class="g-flex-rsbc nav" >

    <div v-for='(vo,index) in data.ico' 
    :key="index" @click='tz2(vo.url)'
    >
     <div class="th-img-text"  >
<a :href="vo.url+'&ts=1'">
      <img :src="url+vo.pic"  style="width: 48px; height: 48px;" />
      <div class="text" style="color:#333;">
       {{ vo.name}}
      </div>
</a>

     </div>
    </div>




   </div>



<div class="xc" style="margin-top:.15rem;">
  <router-link to="/user/vip?active=1">
  <img src="/images/xc.png" alt="" style="width:100%;pointer-events: all;">
  </router-link>
</div>


   <div class="g-mt30 seg-title hot-testing" >

    <div class="th-seg-title"  >
     <div class="left" >
      <div class="item active" >
       <span >热门测评</span>
      </div>
     </div>
     <div class="right" >
      <div @click='huan'>
       <i class="g-plr5 iconfont icon-shuaxin" ></i>
       <span class="text" >换一换</span>
      </div>
     </div>
    </div>



<!-- first second
-->
    <div class="g-flex-rsbc hot-testing__cnt"    v-if='h==1'>
        
     <div class="hot-testing__cnt__item"
  
     v-for="(vo, index) in data.hot1" :key="vo.id"
      v-bind:class="(index==1) ? 'first' : 'second'"
 @click='tz(vo.id)'
     >
      <div class="g-flex-rsbc top" >
       <div class="left" >
        <img  :data-src="url+vo.image" :src="url+vo.image" lazy="loaded" />
       </div>
       <div class="right" >
        <div class="title" >
        {{ vo.catname}}
        </div>
        <div class="desc hide" >
         <span >&yen; 21.90</span>
         <span class="small" >&yen; 199.00</span>
        </div>
       </div>
      </div>
      <div class="bottom" >
       <div >
        <span >{{ vo.hits }}</span>
        <span >人已测</span>
       </div>
       <div class="right" >
        <div >
         去测评
        </div>
       </div>
      </div>
     </div>
    </div>

 <div class="g-flex-rsbc hot-testing__cnt"    v-if='h==2'>
        
     <div class="hot-testing__cnt__item"
  
     v-for="(vo, index) in data.hot2" :key="vo.id"
      v-bind:class="(index==1) ? 'first' : 'second'"
       @click='tz(vo.id)'
     >
      <div class="g-flex-rsbc top" >
       <div class="left" >
        <img  :data-src="url+vo.image" :src="url+vo.image" lazy="loaded" />
       </div>
       <div class="right" >
        <div class="title" >
        {{ vo.catname}}
        </div>
        <div class="desc hide" >
         <span >&yen; 21.90</span>
         <span class="small" >&yen; 199.00</span>
        </div>
       </div>
      </div>
      <div class="bottom" >
       <div >
        <span >{{ vo.hits }}</span>
        <span >人已测</span>
       </div>
       <div class="right" >
        <div >
         去测评
        </div>
       </div>
      </div>
     </div>
    </div>

   </div>

  </div>





<!--
v-bind:class="(active==0)?'item active':'item'"
  -->


  <div class="g-mt30 many-tags" >
   <div class="th-seg-title g-plr15"  >
    <div class="left" >
          <div v-bind:class="(active==2)?'item active':'item'"  v-on:click='qh(2)'>
      <span ><span class="border">趣味</span>测试</span>
     </div>
     <div v-bind:class="(active==0)?'item active':'item'"  v-on:click='qh(0)'>
      <span ><span class="border">热门</span>必测</span>
     </div>
     <div v-bind:class="(active==1)?'item active':'item'"  v-on:click='qh(1)'>
      <span ><span class="border">心理</span>健康</span>
     </div>
   
    </div>
    <div class="right" >
     <div >
         <a href="/list">
      <span class="g-plr5 text" >更多</span>
      <i class="iconfont icon-arrow-right" ></i>
      </a>
     </div>
    </div>
   </div>
     


<van-tabs  v-model="active" animated swipeable >

  <van-tab title="标签 1">


  <div >

   <div class="g-flex-rsbc many-tags__cnt__item"
    v-for='vo in data.cat1' :key="vo.id"
     @click='tz(vo.id)'
    >
    <div class="left" >
     <img  :data-src="url+vo.image" :src="url+vo.image" lazy="loaded" />
    </div>
    <div class="right" >
     <div class="right-top" >
      <div class="title" >
 {{ vo.catname }}
      </div>
      <div class="desc" >
    {{ vo.des }}  </div>

   <div class="l_vip">
     <img src="/images/v1.png" alt="" v-if='vo.v1==1'>
     <img src="/images/v2.png" alt="" v-if='vo.v2==1'>
     <img src="/images/v3.png" alt="" v-if='vo.v3==1'>
     <img src="/images/v4.png" alt="" v-if='vo.v4==1'>
      </div>

     </div>
     <div class="bottom" >
      <div class="price hide" >
       <span >&yen; 19.90</span>
       <span class="small" >&yen; 39.90</span>
      </div>
      <div class="bottom-right" >
       <div class="testing-btn" >
        点击测试
       </div>
       <div class="count" >
         {{ vo.hits }}人已测
       </div>
      </div>
     </div>
    </div>
   </div>





 
   
  </div> 





  </van-tab>
  <van-tab title="标签 2">




<div class="g-flex-rsbc many-tags__cnt__item"
    v-for='vo in data.cat2' :key="vo.id"
     @click='tz(vo.id)'
    >
    <div class="left" >
     <img  :data-src="url+vo.image" :src="url+vo.image" lazy="loaded" />
    </div>
    <div class="right" >
     <div class="right-top" >
      <div class="title" >
 {{ vo.catname }}
      </div>
      <div class="desc" >
    {{ vo.des }}  </div>
   <div class="l_vip">
     <img src="/images/v1.png" alt="" v-if='vo.v1==1'>
     <img src="/images/v2.png" alt="" v-if='vo.v2==1'>
     <img src="/images/v3.png" alt="" v-if='vo.v3==1'>
     <img src="/images/v4.png" alt="" v-if='vo.v4==1'>
      </div>


     </div>
     <div class="bottom" >
      <div class="price hide" >
       <span >&yen; 19.90</span>
       <span class="small" >&yen; 39.90</span>
      </div>
      <div class="bottom-right" >
       <div class="testing-btn" >
        点击测试
       </div>
       <div class="count" >
         {{ vo.hits }}人已测
       </div>
      </div>
     </div>
    </div>
   </div>

  </van-tab>
  <van-tab title="标签 3">

 <div class="g-flex-rsbc many-tags__cnt__item"
    v-for='vo in data.cat7' :key="vo.id"
     @click='tz(vo.id)'
    >
    <div class="left" >
     <img  :data-src="url+vo.image" :src="url+vo.image" lazy="loaded" />
    </div>
    <div class="right" >
     <div class="right-top" >
      <div class="title" >
 {{ vo.catname }}
      </div>
      <div class="desc" >
    {{ vo.des }}  </div>
   <div class="l_vip">
     <img src="/images/v1.png" alt="" v-if='vo.v1==1'>
     <img src="/images/v2.png" alt="" v-if='vo.v2==1'>
     <img src="/images/v3.png" alt="" v-if='vo.v3==1'>
     <img src="/images/v4.png" alt="" v-if='vo.v4==1'>
      </div>


     </div>
     <div class="bottom" >
      <div class="price hide" >
       <span >&yen; 19.90</span>
       <span class="small" >&yen; 39.90</span>
      </div>
      <div class="bottom-right" >
       <div class="testing-btn" >
        点击测试
       </div>
       <div class="count" >
         {{ vo.hits }}人已测
       </div>
      </div>
     </div>
    </div>
   </div>
  </van-tab>

</van-tabs>



  </div>

<bq></bq>

<p style="text-align:center;">
   <router-link to="/user/yinsi">《隐私政策》</router-link><router-link to="/user/yhxy">《用户协议》</router-link>
</p>

 <foot cur='index' ></foot>
<p style="color:#eee;" @click="tc1()">退出</p>

 <ycxf option="home"></ycxf>

</div>
</template>










<script>
// @ is an alias to /src
import Foot from './Foot'
import Bq from './Bq'
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { Tab, Tabs } from 'vant';
import global from './common.vue'
import ycxf from '../components/part/ycxf';

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Tab);
Vue.use(Tabs);
export default {

  name: 'Index',
     components:{
     Foot,Bq,ycxf
    },
  data() {
    return {
  active:2,

data:[],
h:1,
pt:'',
url:'',
   host:null
    }
  },created() {
 //判断浏览器'
 /*
    var vConsole = new VConsole();
*/
if(this.$route.query.shfx!=undefined){
  localStorage.setItem('shfx',this.$route.query.shfx);
}

    this.host=window.location.host;

this.url=global.http;
  var system = { 
   win: false, 
   mac: false, 
   xll: false, 
   ipad: false
  }; 
  //检测平台 
  var p = navigator.platform; 
  system.win = p.indexOf("Win") == 0; 
  system.mac = p.indexOf("Mac") == 0; 
  system.x11 = (p == "X11") || (p.indexOf("Linux") == 0); 
  system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false; 
  //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面 

  if (system.win || system.mac || system.xll || system.ipad) { 
//alert("在PC端上打开的");
 this.pt='pc'
  } else { 
   var ua = navigator.userAgent.toLowerCase(); 
   if(ua.match(/MicroMessenger/i)=="micromessenger") { 
 //alert("在手机端微信上打开的"); 
  this.pt='wx'
   } else { 
  // alert("在手机上非微信上打开的"); 
     this.pt='wap'
   } 
  }
//结束判断


    //公众号获取openid
   if(this.pt=='wx'){
var openidcode=this.$route.query.openidcode;

if(openidcode==undefined){
     var openid=localStorage.getItem('openid');

      if(openid==undefined){

 


if(this.$route.query.shfx==null || this.$route.query.shfx==undefined){
  window.location.href=global.http+"/api/login?host="+this.host;
}else{
  window.location.href=global.http+"/api/login?host="+this.host+"&shfx="+this.$route.query.shfx;

}




      }
}else{
    localStorage.setItem('openid',openidcode);
 this.$router.push('/');
}
}






   var that=this;
        this.$axios.get(global.http+'/api/home').then((response) => {
    that.data=response.data;

 document.title = that.data.sys.name;

    })


  },methods: {
    tc1(){
   

localStorage.clear()
    },
    qh:function(val){
      this.active=val;
console.log(val);
    },
    tc:function(){
Dialog.alert({
  title: '标题',
  message: '弹窗内容',
}).then(() => {
  // on close
});

    },
    huan:function(){
        if(this.h==1){
            this.h=2;
        }else{
            this.h=1;
        }
    },
                tz:function(val){

 this.$router.push('/test/detail?id='+val+'&ts=1')

                },
     tz2:function(val){

          console.log(11);
 this.$router.push(val)

                },
       //微信分享
      WeChatshare(){
        let that=this;
        let data={url:window.location.href.split('#')[0]};
        this.axios.post('/api/v1/signture',data)
        .then((res)=>{
          wx.config({
            debug: true, //开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.data.appId, //必填，公众号的唯一标识
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名
            jsApiList: ['onMenuShareTimeline','onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
          });

          //拼接当前地址
          let shareUrl=window.location.href.split('#')[0]+'#'+window.location.href.split('#')[1];
          shareUrl = shareUrl.split('#')[0] + 'static/html/redirect.html?app3Redirect=' + encodeURIComponent(shareUrl);

          wx.ready(function () {
            //分享给朋友
            wx.onMenuShareAppMessage({
              title: that.fenxi_title,
              desc: that.fenxi_describe,
              link: shareUrl,
              imgUrl: that.fenxi_img,
              success: function (res) {
                alert('分享成功！');
              },
              cancel:function(res){
                alert('分享失败！');
              }
            })
            //分享到朋友圈
            /*
            wx.onMenuShareTimeline({
              title: that.fenxi_title,
              link: shareUrl,
              imgUrl: that.fenxi_img,
              success: function (res) {
                alert('分享成功！');
              },
              cancel:function(res){
                alert('分享失败！');
              }
            })
*/

          });
        })
        .catch((res)=>{
          // console.log(res);
        })
      },         



  },

}
</script>
<style >

.th-seg-title .left .item{margin-right: 0.14rem;}
.van-tabs{margin-top: .1rem;}
.van-tabs--line .van-tabs__wrap {
    height: 44px;
    display: none;
}
.many-tags {
 
 touch-action:pan-y;


    }
.home .many-tags__cnt .load-more, .home .many-tags__cnt__item {
    margin-left: .15rem;
    margin-right: .15rem;
}
.home .many-tags__cnt__item .left {
    margin-right: .1rem;
}
.home .many-tags__cnt__item .left img {
    width: .9rem;
    height: .9rem;
    border-radius: .1rem;
    object-fit: cover;
}


.home .many-tags__cnt__item .right {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}.home .many-tags__cnt__item .right .title {
    font-size: .14rem;
    height: .25rem;
    line-height: .25rem;
    font-weight: 700;
    overflow: hidden;
}
.home .many-tags__cnt__item .right .desc {
    font-size: .12rem;
    height: .24rem;
    color: #9da9b1;
    line-height: .24rem;
    overflow: hidden;
}
.home .many-tags__cnt__item .right .bottom {
    position: relative;
    width: 100%;
    margin-top: .05rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}
.home .many-tags__cnt__item .right .desc {
    font-size: .12rem;
    height: .24rem;
    color: #9da9b1;
    line-height: .24rem;
    overflow: hidden;
}
.home .many-tags__cnt__item .right .bottom .price {
    position: relative;
    top: -.08rem;
    color: #f5b337;
    font-size: .14rem;
    font-weight: 700;
}
.home .many-tags__cnt__item .right .bottom .bottom-right {
    font-size: .12rem;
}
.home .many-tags__cnt__item .right .bottom .bottom-right .testing-btn {
    padding: .06rem .15rem;
    background: #7da8f6;
    color: #fff;
    border-radius: .2rem;
    box-shadow: 0.01rem 0.01rem 0.07rem #7ea9f5;
}
.home .many-tags__cnt__item .right .bottom .bottom-right .count {
    padding-top: .05rem;
    color: #c5cdd3;
}

.home .many-tags__cnt .van-swipe {
    padding-bottom: .1rem;
}
.foot{height: 2rem;width: 100%;color:#fff;}
.ss{height: 2rem;background: #f00;display: block;overflow: hidden;}
.home .swiper{
    margin-bottom: .3rem;
    height: 1.7rem;
}

.home .swiper .van-swipe .van-swipe-item {
    background-color: #c7ccdf;
     position: relative;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}
.home .swiper img {
    width: 100%;
    height: 1.7rem;
    border-radius: .08rem;
    object-fit: cover;
}

.home .swiper .van-swipe{
    margin: .1rem;
    margin-bottom: 0;
    border-radius: .08rem;
    box-shadow: 0.01rem 0.01rem 0.2rem #c7ccdf;
}
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }




.th-seg-title .left, .th-seg-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.th-seg-title .left{
    font-size: .16rem;
}
.th-seg-title .left .item.active{
    font-size: .18rem;
    font-weight: 700;
    color: #000;
}
.th-seg-title .left .item.active{
    font-size: .18rem;
    font-weight: 700;
    color: #000;
}

.home .hot-testing__cnt {
    margin-top: .2rem;
}.home .hot-testing__cnt .first {
    background: -webkit-linear-gradient(left,#f6ca81,#f7ac39 40%);
    background: linear-gradient(
90deg
,#f6ca81 0,#f7ac39 40%);
    box-shadow: 0.01rem 0.01rem 0.05rem #f6ca81;
}
.home .hot-testing__cnt__item {
    width: 48.5%;
    box-sizing: border-box;
    border-radius: .08rem;
    color: #fff;
}
.home .hot-testing__cnt__item .top {
    margin: .1rem .06rem;
}
.home .hot-testing__cnt__item .top .left {
    margin-right: .06rem;
    border-radius: 50%;
    width: .48rem;
    height: .48rem;
    padding: .04rem;
    box-sizing: border-box;
    background: #fff;
}
.home .hot-testing__cnt__item .top .left img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    min-width: .38rem;
    min-height: .38rem;
    object-fit: cover;
}
.home .hot-testing__cnt__item .top .title {
    font-size: .14rem;
    height: .24rem;
    line-height: .24rem;
    overflow: hidden;
    color: #fff;
}

.home .hot-testing__cnt__item .bottom {
    padding: .1rem;
    position: relative;
    color: #efefef;
}

.home .hot-testing__cnt .first .bottom .right {
    color: #f7ac39;
}
.home .hot-testing__cnt__item .bottom .right {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: .05rem .1rem;
    padding-left: .18rem;
    border-top-left-radius: .3rem;
    border-bottom-right-radius: .08rem;
}
.home .hot-testing__cnt .second {
    background: -webkit-linear-gradient(left,#9bbcef,#7aa8f3 40%);
    background: linear-gradient(
90deg
,#9bbcef 0,#7aa8f3 40%);
    box-shadow: 0.01rem 0.01rem 0.05rem #9bbcf1;
}
.home .hot-testing__cnt .second .bottom .right {
    color: #7aa8f3;
}
.th-seg-title{
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}




.home .many-tags__cnt {
    margin-top: .2rem;
}

.home .many-tags__cnt .keydowrds-w {
    margin: 0 .15rem;
    white-space: nowrap;
    overflow: hidden;
}


.home .many-tags__cnt .keydowrds {
    display: inline-block;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.home .many-tags__cnt .keydowrds span {
    display: inline-block;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    font-size: .12rem;
    margin-right: .1rem;
    padding: .06rem .2rem;
    background: #fff;
    border: .01rem solid #7ea9f5;
    color: #7ea9f5;
    border-radius: .2rem;
}
body{padding-bottom: 0rem;}
.home {
    padding-bottom: 0rem;
}

.home .copyright{margin: 0.3rem
 0 0rem
;}
body{padding-bottom: 0.7rem;}

 @media screen and (min-width: 600px) { 
  
  .home .swiper img {
    width: 100%;
    height: 2.4rem;
    border-radius: .08rem;
    -o-object-fit: cover;
    object-fit: cover;
}



.home .swiper {
    margin-bottom: .3rem;
    height: 2.4rem;
}



  }


.l_vip img{width: .18rem;margin-right: 0.05rem;}
.xc img{width: 100%;}

</style>
