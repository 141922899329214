<template>
    <div class="ycxf" v-if="pt=='wx'">
        <router-link :to="data.url">
        <img :src="data.pic" alt="">
    </router-link>
    </div>
</template>

<style lang="less" scoped>

.ycxf{
    position: fixed;top:2.5rem;right:0px;
    img{width:.9rem;}

}
</style>

<script>
import global from './../../views/common.vue'
export default {
  name: "testingresult",
   data() {
    return {
data:undefined,
pt:null
    }
  },  components:{

  },
  created() {
    var system = { 
   win: false, 
   mac: false, 
   xll: false, 
   ipad: false
  }; 
  //检测平台 
  var p = navigator.platform; 
  system.win = p.indexOf("Win") == 0; 
  system.mac = p.indexOf("Mac") == 0; 
  system.x11 = (p == "X11") || (p.indexOf("Linux") == 0); 
  system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false; 
  //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面 

  if (system.win || system.mac || system.xll || system.ipad) { 
//alert("在PC端上打开的");
 this.pt='pc'
  } else { 
   var ua = navigator.userAgent.toLowerCase(); 
   if(ua.match(/MicroMessenger/i)=="micromessenger") { 
 //alert("在手机端微信上打开的"); 
  this.pt='wx'
   } else { 
  // alert("在手机上非微信上打开的"); 
     this.pt='wap'
   } 
  }



  var that=this;
        this.$axios.get(global.http+'/test/ycxf?xf='+that.option).then((res) => {
    that.data=res.data;
    })


console.log(this.option);


  },
  methods: {




  },
      props: {
    option: String
  }
  
}
</script>