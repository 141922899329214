import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '/public/css/style.css'
/*
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
*/

let echarts = require('echarts/lib/echarts')
import './components/icon-file/iconfont.js'
// 引入折线图等组件
require('echarts/lib/chart/line')
require('echarts/lib/chart/bar')
require('echarts/lib/chart/pie')
require('echarts/lib/chart/radar')
Vue.prototype.$echarts = echarts


Vue.config.productionTip = false
import './mock'
/*
require('./mock')
*/

import axios from 'axios'
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


