<script type="text/javascript">
// 定义一些公共的属性和方法
//阿里云
/*
     const http = 'https://adm56.51lihaoedu.top';
     const ht =   'http://huitui.51lihaoedu.top';
     const fmht =  'http://xcx.lhyuming01.top/list';
     const xzht = 'http://zfht.51lihaoedu.top';
     const xfht = 'http://xuanfu.51lihaoedu.top';
     const syt = 'https://syt5.51lihaoedu.top';
     const https = true;
*/
//北京


const http = 'https://alyadm.520bixin.com';
const ht = 'http://alywap.520bixin.com';
const  fmht = 'http://xcx.lhyuming01.top/list';
const xzht = 'http://alywap.520bixin.com';
const xfht = 'http://alywap.520bixin.com';
const syt = 'https://syt4.51lihaoedu.top';
const https = true;



/*
const http = 'https://alyadm.520bixin.com';
 const ht = 'http://alywap.520bixin.com';
const  fmht = 'http://alywap.520bixin.com';
 const xzht = 'http://alywap.520bixin.com';
 const xfht = 'http://alywap.520bixin.com';
const syt = 'https://syt.51lihaoedu.top';
 const https = true;
*/





 //测试
/*
 const http = 'http://x1.hbeuropa.com';
 const ht =   'http://huitui.hbeuropa.com';
 const fmht = 'http://fmht.hbeuropa.com';
 const xzht = 'http://huitui.hbeuropa.com';
 const xfht = 'http://xuanfu.51lihaoedu.top';
 const  syt = 'https://syt.51lihaoedu.top';
 const https = true;
*/
//https


/*
     const http = 'https://tupian.51lihaoedu.top';
     const ht =   'http://huitui.520bixin.com';
     const fmht =  'http://huitui.520bixin.com';
     const xzht = 'http://huitui.520bixin.com';
     const xfht = 'http://huitui.520bixin.com'; 
     const syt = 'https://syt.51lihaoedu.top';
      const https = true;
*/


//https2

/*
     const http = 'http://adm_1.51lihaoedu.top';
     const ht =   'http://test_1.51lihaoedu.top';
     const fmht =  'http://test_1.51lihaoedu.top';
     const xzht = 'http://test_1.51lihaoedu.top';
     const xfht = 'http://test_1.51lihaoedu.top';
     const syt = 'https://syt5.51lihaoedu.top';
      const https = true;
*/

     const ym_arr=['gdttgwo.zxx1359.top','gdttg.lhwljy.top','gdttg.zxx1359.top'];

    function commonFun() {
      
    }
    // 暴露出这些属性和方法
    export default {
        http,
        ht,
        fmht,
        xzht,
        xfht,
        commonFun,
        https,
        ym_arr,
        syt
    }
</script>
